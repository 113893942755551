// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-templates-recorridos-tsx": () => import("./../../../src/templates/recorridos.tsx" /* webpackChunkName: "component---src-templates-recorridos-tsx" */)
}

